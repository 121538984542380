import React from "react";
import "./PublicationCard.css";
import { Fade } from "react-reveal";

export default function GithubRepoCard({ repo, theme }) {
  return (
    <div>
      <Fade bottom duration={2000} distance="40px">
        <div
          className="publication-card-div"
          key={repo.id}
          style={{ backgroundColor: theme.highlight }}
        >
          <div className="publication-name-div">
            <p className="publication-name" style={{ color: theme.text }}>
              {repo.name}
            </p>
          </div>
          <p className="publication-description" style={{ color: theme.text }}>
            {repo.description}
          </p>
        </div>
      </Fade>
    </div>
  );
}
