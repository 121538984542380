const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

const seo = {
  title: "Gabriel Gomes",
  description:
    "Developing is an unique and endless passion, and if you are here, come and fall in love with this world without boundaries.",
  og: {
    title: "Gabriel Gomes Portfolio",
    type: "website",
    url: "https://gabrielgomes.dev.br/",
  },
};

const greeting = {
  title: "Gabriel Gomes",
  logo_name: "Gabriel Gomes",
  nickname: "logsprr",
  subTitle:
    "Developing is an unique and endless passion, and if you are here, come and fall in love with this world without boundaries.",
  resumeLink:
    "https://drive.google.com/file/d/1OSYuiedh-IHKmyH0DInVT31o9VvJ3PiX/view?usp=sharing",
  portfolio_repository: "https://github.com/logsppr/portfolio",
  githubProfile: "https://github.com/logsprr",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/logsprr",
    fontAwesomeIcon: "fa-github",
    backgroundColor: "#181717",
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/gabrielgomeslogs/",
    fontAwesomeIcon: "fa-linkedin-in",
    backgroundColor: "#0077B5",
  },
  {
    name: "Gmail",
    link: "mailto:gabrielgomeslogs@gmail.com",
    fontAwesomeIcon: "fa-google",
    backgroundColor: "#D14836",
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/gabrielgomeslogs/",
    fontAwesomeIcon: "fa-facebook-f",
    backgroundColor: "#1877F2",
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/gabriellogs/",
    fontAwesomeIcon: "fa-instagram",
    backgroundColor: "#E4405F",
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building resposive website front end using React-Redux",
        "⚡ Developing mobile applications using Flutter, React Native",
        "⚡ Creating application backend in Node, Express",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on GCP and AWS",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "UI/UX Design",
      fileName: "DesignImg",
      skills: [
        "⚡ Designing highly attractive user interface for mobile and web applications",
        "⚡ Customizing logo designs and building logos from scratch",
        "⚡ Creating the flow of application functionalities to optimize user experience",
      ],
      softwareSkills: [
        {
          skillName: "Adobe XD",
          fontAwesomeClassname: "simple-icons:adobexd",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
      ],
    },
  ],
};

const competitiveSites = {
  competitiveSites: [],
};

const degrees = {
  degrees: [
    {
      title: "Goias State University",
      subtitle: "B.Tech. in Information Systems",
      logo_path: "ueg_logo.png",
      alt_name: "Goias University",
      duration: "2018 - Present",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like Node, Java, Javascript, Algorithms, DBMS, OS, SA etc.",
        "⚡ Apart from this, I have done courses on TypeScript, JavaScript, ML, Scrum.",
        "⚡ I have published several articles during my academic career, it was a great milestone to be able to research and try to solve problems in a scientific way.",
      ],
      website_link: "http://www.ueg.br/",
    },
  ],
};

const certifications = {
  certifications: [],
};

const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description: "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Senior Full Stack React",
          company: "Lion Mane Software",
          company_url: "http://lionmane.com/",
          logo_path: "lion_mane.png",
          duration: "February 2022 - Present",
          location: "Remote, Guatemala",
          description: "",
          color: "#0879bf",
        },
        {
          title: "Support Lead and Node Developer",
          company: "Alive",
          company_url: "https://alive.app.br/",
          logo_path: "alive.png",
          duration: "March 2021 - Present",
          location: "Remote, Brazil",
          description: "",
          color: "white",
        },
        {
          title: "TI Lead",
          company: "Digital SA",
          company_url: "https://digitalsa.com.br/",
          logo_path: "digitalsa.png",
          duration: "Jul 2019 - Present",
          location: "Remote, Brazil",
          description: "",
          color: "#0879bf",
        },
        {
          title: "Full Stack",
          company: "Trixx App",
          company_url: "http://trixxapp.com.br/",
          logo_path: "trixx.png",
          duration: "Jul 2021 - Present",
          location: "Remote, Brazil",
          description: "",
          color: "blue",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Mobile Developer",
          company: "App Fiscal",
          company_url: "https://www.appfiscal.com/",
          logo_path: "app_fiscal.png",
          duration: "Aug 2019 - Dec 2019",
          location: "Remote, Brazil",
          description: "",
          color: "#ee3c26",
        },
        {
          title: "Web Developer",
          company: "Mult Profissoes",
          company_url: "http://multprofissoes.com.br/site/",
          logo_path: "multprofissoes.png",
          duration: "Feb 2019 - Jun 2019",
          location: "Remote, Brazil",
          description: "",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Electronic Voting Machine",
          company: "TRE",
          company_url: "https://www.tre-go.jus.br/",
          logo_path: "tre.png",
          duration: "Nov 2020 - Nov 2020",
          location: "Goias, Brazil",
          description: "",
          color: "#4285F4",
        },
        {
          title: "Developer Students Club Lead",
          company: "DSC Goias State University",
          company_url: "https://www.instagram.com/dsc.ueg/",
          logo_path: "google_logo.png",
          duration: "Aug 2020 - Aug 2021",
          location: "Goias, Brazil",
          description: "",
          color: "#0C9D58",
        },
      ],
    },
  ],
};

const projectsHeader = {
  title: "Projects",
  description:
    "Most of my personal projects don't even go to Github on a whim, but here are some that I really like, if you want to see projects I developed in my jobs, get in touch.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "profile.JPG",
    description:
      "I'm always available for a chat, whether it's about any topic, be it node, java, typescript, or even cooking, which is something I love, so just give me a call.",
  },
  addressSection: {
    title: "Address",
    subtitle: "Maurilândia, Goiás, Brazil - 75930000",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/jgB1CbeTGx9BM921A",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+55 64992816487",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  contactPageData,
};
