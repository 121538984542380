export const blackTheme = {
  body: "black",
  text: "white",
  highlight: "#5A6377",
  dark: "#000000",
  secondaryText: "#5A6377",
  imageHighlight: "#fca311",
  compImgHighlight: "#E6E6E6",
  jacketColor: "#8d99ae",
  headerColor: "#fca31177",
};

export const chosenTheme = blackTheme;
